import React from 'react';
import { Image } from 'react-bootstrap';

const Header = () => {
  return (
    <>
        <Image src="images/banner.png" rounded fluid />
        {/* Horizontal Line */}
        <hr className="my-4" />
    </>
  );
}

export default Header;