import { Outlet } from "react-router-dom";
import { Container, Row, Col } from 'react-bootstrap';
import Header from './Header';
import Footer from './Footer';

const Layout = () => {
  return (
    <>
      <Container className="text-center mt-5">
        <Row>
          <Col>
            <Header/>
            <Outlet />
            <Footer/>
          </Col>
        </Row>
      </Container>
    </>
  )
};

export default Layout;