import React from 'react';
import { Image, Button } from 'react-bootstrap';

const UnderConstruction = () => {
  return (
    <div>
      <Image src="images/under_construction.png" rounded fluid width={500}/>
      <p className="lead">Our website is currently under construction.</p>
      <p>If you have any queries, please get in touch by email:</p>
      <Button
        variant="primary"
        href="mailto:admin@holyspiritceltic.org"
      >
        Email Us
      </Button>
    </div>
  );
}

export default UnderConstruction;