import React from 'react';
import * as Icon from 'react-bootstrap-icons';

const Footer = () => {
  return (
    <>
      {/* Horizontal Line */}
      <hr className="my-4" />
      <div>
        <div>
          <p>
            <span>
              <span>The Becket School, The Becket Way, Wilford Lane, West Bridgford. NG2 7QY
              </span>
            </span>
          </p>
        </div>
        <div>
          {/* Social Media Icons */}
          <div className="d-flex justify-content-center mt-4">
            <a
              href="https://twitter.com/nottshscfc"
              target="_blank"
              rel="noopener noreferrer"
              className="mx-3 social-icon"
              aria-label="Twitter"
            >
              {/*<svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32ZM24.4133 7L17.7124 14.7893H17.7121L25 25.3955H19.6401L14.7324 18.2533L8.58799 25.3955H7L14.0274 17.2273L7 7H12.3599L17.0071 13.7633L22.8255 7H24.4133ZM14.8255 16.299L15.5375 17.3174V17.3178L20.3871 24.2545H22.8262L16.8832 15.7537L16.1712 14.7353L11.5992 8.19542H9.16016L14.8255 16.299Z" style={{ fill: 'white' }}></path></svg>*/}
              <Icon.TwitterX 
                size={30}
                color="#1DA1F2"
              />
            </a>
            <a
              href="https://www.instagram.com/holyspiritceltic/"
              target="_blank"
              rel="noopener noreferrer"
              className="mx-3 social-icon"
              aria-label="Instagram"
            >
              <Icon.Instagram
                size={30}
                color="#E4405F"
              />
            </a>
          </div>
          <p className="mt-4">
            <span>
              <span>© COPYRIGHT 2024 HOLY SPIRIT CELTIC FOOTBALL CLUB. ALL RIGHTS RESERVED.&nbsp;</span>
            </span>
          </p>
        </div>
      </div>
    </>
  );
}

export default Footer;